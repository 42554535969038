@import url(https://fonts.googleapis.com/earlyaccess/cwtexyen.css);

nav{
  -webkit-locale: auto;
  line-height: 1;
  color: #202020;
  font-size: 100%;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding: 0px 18px 0px 0px;
}
nav ul{
  -webkit-locale: auto;
  line-height: 1;
  color: #202020;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-weight: inherit;
  font-style: inherit;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
  list-style: none;
  display: flex;
  align-items: center;
}
nav ul li{
  -webkit-locale: auto;
  line-height: 1;
  color: #202020;
  list-style: none;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-weight: inherit;
  font-style: inherit;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
  display: flex;
  cursor: pointer;
}
nav ul li span {
  color: #616161;
  padding: 0px 8px 0px 8px;
}
nav ul li a{
  -webkit-locale: auto;
  list-style: none;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  margin: 0;
  border: 0;
  outline: 0;
  font-weight: inherit;
  font-style: inherit;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
  text-decoration: inherit;
  display: flex;
  align-items: center;
  color: #616161;
  text-align: center;
  line-height: 1.4;
  padding: 0 1rem;
  height: 3.6rem;
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
}

nav ul li a:hover{
  color: #202020;
  background: #fafafa;
  border-bottom: 2px solid #007bff;
}
nav ul li:first-child a:hover{
  color: #202020;
  background: transparent;
  border-bottom: 2px solid transparent;
}
#navigationMobile nav ul li img{
  height: 20px;
  width : 20px;
  margin-right: 20px;
}
#stack{
  transition: all 0.4s ease-out;
  opacity: 1;
  display: none;
  text-align: center;
  padding: 0;
  height: 100vh;
}
#stack li {
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 10px;
  padding-top: 8px;
  padding-bottom: 4px;
  border-top: 1px solid rgb(201, 201, 201);
  line-height: 20px;
  list-style:none;
}
#stack ul li {
  opacity: 1;
}

@media screen and (max-width: 767.98px)  {
  #navigationDesktop {
    display: none;
  }
  #navigationMobile {
    display: block;
  }
}
@media screen and (min-width: 767.98px) {
  #navigationMobile {
    display: none;
  }
  #navigationDesktop {
    display: block;
  }
}
