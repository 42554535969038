
.AppNavigation{
    background-color: #FAFAFA;
    height: 100vh;
    width: 300px;
    top: 0px;
    left: 0px;
    bottom: 0px;
    position: fixed;
    margin-top: 0;
    position: fixed;
}

.tabList{
    margin-top: 220px;
    width: 100%;
}
.tabList ul{
    list-style:none;
    margin: 0;
    padding: 0;
}

.tabList ul li{
    height: 34px;
    padding: 10px;
    display: flex;
    align-items: center;
}
.tabList ul li:hover{
   background-color: #F5F5F5;
}

.tabList ul li .tabListItemImg{
    height: 30px;
    width: 30px;
    border: 1px solid #CECECE;
    border-radius: 11px;
}

.tabList ul li p{
    text-decoration: none;
    color: #575757;
    font-size: 22px;
    font-weight: bold;
    display: inline-block;
    vertical-align: middle;
    padding: 0px 0px 0px 12px;
}

.userProfile{
    width: 100%;
    height: 80px;
    box-sizing: border-box;
    padding: 20px;
    position: absolute;
    bottom: 20px;;
    left: 0;
}
.userProfile img{
    float: left;
    width: 40px;
    height: 40px;
    background: #F7F7F7;
}

.userProfile span{
    padding-left: 18px;
    font-size: 20px;
    font-weight: bold;
}

.userProfile div{
    float: right;
    width: 20px;
    height: 35px;
    font-size: 24px;
    font-weight: bold;
    border: none;
    background: #F7F7F7;
    cursor: pointer;
}
