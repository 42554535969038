#blurBackground {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-backdrop-filter: blur(7px);
    backdrop-filter: blur(10px); /* Chrome and Opera */
    background-color: rgba(0, 0, 0, 0.3);
    display: none;
    z-index: 99;
}
.loginSheetBackground {
    width: 370px;
    position: absolute;
    left: 50%;
    top: 50%;
    WebkitTransform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 100;
    padding: 32px;
}
#loginSheet{
    width: 90%;
    height: 90%;
    margin: 5%;
}
#loginLogo {
    width : 128px;
    height : 128px;
    left: 50%;
    top: 50%;
    WebkitTransform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    display: block;
    top: 20%;
    position: absolute;
}
.formContainer{
    position: fixed;
    top: 55%;
    left: 50%;
    WebkitTransform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
.loginInput {
    border:0.5px rgb(200, 200, 200) solid;
    border-radius: 12px;
    float: inherit;
    margin-bottom: 16px;
    width: 100%;
    -webkit-box-direction: normal;
    line-height: 18px;
    background: #fafafa;
    padding: 9px 8px 7px 8px;
    font-size: 16px;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */
}
.loginInput:focus {
    border-color: rgb(158, 158, 158);
    outline:none;
}
.loginInput::-webkit-input-placeholder {
    font-weight: 700;
    font-size: 12px;
}
.loginSubmit {
    width: 100%;
    border-radius: 30px;
    padding: 12px 0px;
    font-weight: 600;
    border: 2px solid transparent;
    margin-bottom: 14px;
    cursor: pointer;
}
.loginSubmitContainer{
        line-height: 19px;
        font-size: 15px;
        display: inline-flex;
        align-items: center;
}
.loginSubmit:focus{
    outline: none;
}
