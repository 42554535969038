.loginContent{
    margin: auto;
    max-width: 62.5em;
    width: 100%;
    height: 80vh;
}

.login_intro{
    position: relative;
    float: left;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
@media screen and (min-width: 849px) {
    .loginLeft{
        width: 40%;
        height: 80vh;
        float:left;
        display: block;
        padding: 5%;
    }
    .loginRight{
        width: 50%;
        height: 80vh;
        position: relative;
        float:right;
    }
}
@media screen and (max-width: 850px) {
    .loginLeft{
        width: 0%;
        display: none;
    }
    .loginRight{
        width: 100%;
        height: 80vh;
        position: relative;
        float:right;
    }
}

.loginSheetBackground {
    width: 370px;
    height: 500px;
    position: absolute;
    left: 50%;
    top: 50%;
    WebkitTransform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 100;
}
