.basicBackView{
    border-radius:12px;
    background-color: white;
    box-shadow: 0 10px 11px 2px rgba(231, 231, 231, .3);
    box-sizing: border-box;
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
::-moz-selection { /* Code for Firefox */
  background: #F1F1F1;
}

::selection {
  background: #F1F1F1;
}

.fadeIn {
animation: fadeIn ease 0.5s;
-webkit-animation: fadeIn ease 0.5s;
-moz-animation: fadeIn ease 0.5s;
-o-animation: fadeIn ease 0.5s;
-ms-animation: fadeIn ease 0.5s;
}
@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}
