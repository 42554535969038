.App {
  text-align: center;
  height: 2000px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 4s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.appContainer{
  padding-top: 50px;
  background-color: 'red';
  display: flex;
  flex-direction: row;
}

@media screen and (max-width: 767.98px)  {
  .leftComponent{
      display: none;
  }
}
@media screen and (min-width: 767.98px) {
  .leftComponent{
      width: 300px;
      display: block;
  }
}
.rightComponet{
    display: inline-block;
    flex: 1;
}
