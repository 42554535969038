.collectionMain{
    display: flex;
    width: 100%;
    flex-direction: row;
}
@media screen and (max-width: 767.98px)  {
  .collectionSide{
      display: none;
  }
}
@media screen and (min-width: 767.98px) {
  .collectionSide{
      width: 340px;
      height: 1000px;
      display: block;
  }
}

.collectionContent{
    flex-grow: 1;
    box-sizing: border-box;
    display: inline-block;
    float: left;
    box-sizing: border-box;
    padding: 0 20px 0 20px;
}
.languageFilter{
    float: left;
}
.languageFilter h2{
    clear: both;
    color: #575757;
}
.languageFilter ul{
    padding: 0;
}
.languageFilter ul li{
    float: left;
    padding: 5px 10px 5px 10px;
    list-style:none;
    list-style-type: none;
    cursor: pointer;
}
.languageFilter hr{
    clear: both;
    box-sizing: border-box;
    padding: 20px;
}
.languageItem {
    border: 3px solid #C4C4C4;
    color: #C4C4C4;
    font-weight:bold;
    border-radius: 13px;
    padding: 2px 16px 2px 16px;
}
.languageFilter ul li:hover .languageItem{
    border: 3px solid #007AFF;
    color: #007AFF;
}

.selected{
    border: 3px solid #007AFF;
    color: #007AFF;
}

.pageItems{
    float: left;
    width: 100%;
}
.pageItems ul{
    overflow: hidden;
    padding-inline-start: 0px;
    width: 100%;
}

.pageItems ul li{
    width: 100%;
    height: 100px;
    margin: 10px 0 10px 0;
    padding: 10px;
    box-sizing: border-box;
}

.pageItems ul li:active{
    transition-duration: 300ms;
    transform: scale(0.98);
}
.pageItems ul li img {
    width: 60px;
    height: 60px;
    float: left;
    padding: 10px;
}

.pageItems ul li p {
    font-family: 'PingFangTC-Semibold';
    font-size: 20px;
    color: #707070;
    text-align: left;
}
