body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 18px;
  font-weight:400;
  background-color: #F7F7F7;
  overflow: auto;
}

header{
  -webkit-locale: auto;
  line-height: 1;
  color: #202020;
  font-size: 100%;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  top: 0;
  left: 0;
  right: 0;
  z-index: 20;
  position: fixed;
  transition: background-color 350ms cubic-bezier(0.25, 0.8, 0.25, 1);
  -webkit-backdrop-filter: blur(7px);
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.8);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
