.loginFooter {
    margin: 0;
    background-color: white;
    color: #8a8d91;
    bottom: 0px;
    padding: 10px 36px 20px 36px;
}
.loginFooter ul > li{
    padding: 5px 0px 0px 10px;
    display: inline-block;
    font-size: 16px;
}

.loginFooter ul > li > a{
    font-size: 16px;
    color: #8a8d91;
    text-decoration-line: none;
}
